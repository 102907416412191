<template>
  <div class="content_box">
    <el-card
      style="margin-bottom: 20px"
      :body-style="{ height: '600px', overflow: 'auto' }"
    >
      <div slot="header">
        <span v-show="!inputVisible">{{ slnName }}</span>

        <el-input
          class="button-new-tag"
          v-if="inputVisible"
          v-model="copySlnName"
          ref="saveSlnNameRef"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>

        <el-button
          v-else
          type="text"
          icon="el-icon-edit"
          @click="showInput"
        ></el-button>
        <!-- 新增系统方案 -->
        <span style="float: right">
          <el-select
            style="margin-right: 10px"
            v-model="addSlnDetialType"
            filterable
            allow-create
            @focus="onFocus"
            default-first-option
            placeholder="请选择选解决方案类型名称"
          >
            <el-option
              v-for="item in slnDetialTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <el-button type="primary" size="medium" @click="addNewSystem"
            >新增系统</el-button
          >
        </span>
      </div>
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="24"
          :lg="24"
          v-for="item in systemData"
          :key="item.id"
          style="margin-bottom: 20px"
        >
          <SlnCard
            :id="item.id"
            :title="item.systemName"
            :subTitle="item.subsystemName"
            :areaName="item.areaName"
            :remark="item.remark"
            :productList="item.productList"
            @changeSystemName="changeSystemName"
            @changeSubsystemName="changeSubsystemName"
            @changeAreaName="changAreaName"
            @changeRemark="changeRemark"
            @deleteSystem="deleteSystem"
            @pushProductList="pushProductList"
            @deleteProduct="deleteProduct"
            @eidtProduct="eidtProduct"
          />
        </el-col>
      </el-row>
    </el-card>
    <el-card :body-style="{ overflow: 'auto' }">
      <span style="line-height: 40px">系统数:{{ systemCount }}</span>
      <span style="line-height: 40px; padding: 0 20px"
        >设备数量:{{ allAmount }}</span
      >
      <span style="line-height: 40px">预计金额:{{ allPrice }}</span>
      <span style="float: right">
        <el-button size="medium" @click="emptySystemData" type="danger"
          >清空方案</el-button
        >
        <el-button size="medium" @click="pushIndex" type="info"
          >取消保存</el-button
        >
        <el-button size="medium" type="success" @click="submitSysteme"
          >保存上传</el-button
        >
      </span>
    </el-card>
  </div>
</template>
<script>
import { addSln } from '@/api/solution'
import SlnCard from './components/SlnCard.vue'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  components: {
    SlnCard,
  },
  data() {
    return {
      // 解决方案名称
      slnName: '雪意®智能全宅设计方案',
      copySlnName: '雪意®智能全宅设计方案',
      // 更改解决方案名称输入框
      inputVisible: false,
      addSlnDetialType: '',
      // 默认系统选项
      slnDetialTypeOptions: [
        {
          lable: '智能家居中控系统',
          value: '智能家居中控系统',
          subsystemName: '主控',
          areaName: '全区域',
        },
        {
          lable: '智能灯光控制系统',
          value: '智能灯光控制系统',
          subsystemName: '灯光',
          areaName: '全区域',
        },
        {
          lable: '智能电动窗帘控制系统',
          value: '智能电动窗帘控制系统',
          subsystemName: '电动窗帘',
          areaName: '全区域',
        },
        {
          lable: '智能舒适环境控制系统',
          value: '智能舒适环境控制系统',
          subsystemName: '舒适环境',
          areaName: '全区域',
        },
        {
          lable: '智能影音控制系统',
          value: '智能影音控制系统',
          subsystemName: '影音',
          areaName: '影院',
        },
      ],
      //系统方案数据
      systemData: [],
    }
  },
  methods: {
    addNewSystem() {
      if (!this.addSlnDetialType) {
        return this.$message.error('新增系统名称不能为空')
      }

      let id = this.systemData.length + 1

      while (
        this.systemData.some((item) => {
          return item.id == id
        })
      ) {
        id++
      }

      if (
        this.slnDetialTypeOptions.some((item) => {
          return item.value == this.addSlnDetialType
        })
      ) {
        let entity = this.slnDetialTypeOptions.filter((item) => {
          return item.value == this.addSlnDetialType
        })[0]

        this.systemData.push({
          id,
          systemName: entity.value,
          areaName: entity.areaName,
          subsystemName: entity.subsystemName,
          remark: '',
          productList: [],
        })
      } else {
        this.systemData.push({
          id,
          systemName: this.addSlnDetialType,
          areaName: '全区域',
          subsystemName: '控制',
          remark: '',
          productList: [],
        })
      }
    },
    // 展示更改解决方案名称输入框
    showInput() {
      this.inputVisible = true
      this.copySlnName = this.slnName
      this.$nextTick((_) => {
        this.$refs.saveSlnNameRef.$refs.input.focus()
      })
    },
    // 解决方案名称更改确认
    handleInputConfirm() {
      let inputValue = this.copySlnName
      if (inputValue) {
        this.slnName = inputValue
      }
      this.inputVisible = false
      this.copySlnName = ''
    },
    // 更改系统名称
    changeSystemName(id, newName) {
      this.systemData.forEach((item, index, arr) => {
        if (item.id == id) {
          arr[index].systemName = newName
          return
        }
      })
    },
    // 更改子系统名称
    changeSubsystemName(id, newName) {
      this.systemData.forEach((item, index, arr) => {
        if (item.id == id) {
          arr[index].subsystemName = newName
          return
        }
      })
    },
    // 更改系统备注
    changeRemark(id, newRemark) {
      this.systemData.forEach((item, index, arr) => {
        if (item.id == id) {
          arr[index].remark = newRemark
          return
        }
      })

      console.log(this.systemData)
    },
    // 更改区域名称
    changAreaName(id, newAreaName) {
      this.systemData.forEach((item, index, arr) => {
        if (item.id == id) {
          arr[index].areaName = newAreaName
          return
        }
      })
    },
    // 删除系统
    deleteSystem(id) {
      this.systemData = this.systemData.filter((item) => {
        return item.id != id
      })
    },
    // 向系统追加商品
    pushProductList(id, productObj) {
      this.systemData.forEach((item, index, arr) => {
        if (item.id == id) {
          arr[index].productList.push(productObj)
          return
        }
      })
    },
    // 删除当前系统中的商品
    deleteProduct(id, obj) {
      this.systemData.forEach((item, index, arr) => {
        if (item.id == id) {
          arr[index].productList = arr[index].productList.filter((pItem) => {
            return !(
              pItem.productId == obj.productId &&
              pItem.productModel == obj.productModel &&
              pItem.productSpecs == obj.productSpecs
            )
          })

          return
        }
      })
    },
    eidtProduct(id, data) {
      this.systemData.forEach((item, index, arr) => {
        if (item.id == id) {
          arr[index].productList = arr[index].productList.map((pItem) => {
            if (
              pItem.productId == data.productId &&
              pItem.productModel == data.productModel &&
              pItem.productSpecs == data.productSpecs
            ) {
              return data
            }

            return pItem
          })

          return
        }
      })
    },
    // 清空当前预设的场景解决方案
    emptySystemData() {
      this.$confirm(
        '当前操作将会清空当前预设的所有场景解决方案, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.systemData = []
        })
        .catch(() => {})
    },
    // 返回场景解决方案首页
    pushIndex() {
      this.$confirm(
        '当前操作将会清空当前预设的所有场景解决方案, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          // this.$router.push({ path: '/Solution' })
          this.$router.go(-1)
        })
        .catch(() => {})
    },
    async submitSysteme() {
      let data = {
        slnName: this.slnName,
        systemData: this.systemData,
      }
      await addSln(data)
      this.$router.go(-1)
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
  computed: {
    systemCount() {
      return this.systemData.length
    },
    // 预计金额
    allPrice() {
      let arr = this.systemData.reduce((prev, curr) => {
        return [...prev, ...curr.productList]
      }, [])

      const priceArr = arr.map((item) => {
        return Number(item['price'] * item['amount'])
      })

      let sums = 0

      if (!priceArr.every((value) => isNaN(value))) {
        sums = priceArr
          .reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          .toFixed(2)
        sums += ' 元'
      } else {
        sums = 'N/A'
      }

      return sums
    },
    // 设备数量
    allAmount() {
      let arr = this.systemData.reduce((prev, curr) => {
        return [...prev, ...curr.productList]
      }, [])

      const amountArr = arr.map((item) => {
        return Number(item['amount'])
      })

      let sums = 0

      if (!amountArr.every((value) => isNaN(value))) {
        sums = amountArr
          .reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          .toFixed(0)
        sums += ' 件'
      } else {
        sums = 'N/A'
      }

      return sums
    },
  },
}
</script>
<style lang="scss" scoped>
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  width: 200px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
